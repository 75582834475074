.dialogsContainer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
}

.dialog {
  position: absolute;
  left: 50%;
  top: 33%;
  transform: translate(-50%, -33%);
  float: left;
  max-height: 90%;
}

.dialog .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.dialog .list .icon {
  filter: initial;
  -webkit-filter: initial;
}

.dialog.small {
  width: 336px;
  color: white;
}

.dialog.small input{
  /*width: 336px;*/
  color: #000;
}

.dialog.small .thumbImage {
  width: 335px;
}

.dialog.wide {
  /*width: 600px;*/
  width: 40%;
  color: white;
  padding: 9px 18px;
}

.dialog.wide input {
  /*width: 600px;*/
  color: #000;
}

@media only screen and (max-width: 1300px) {
  .dialog.wide {
    /*width: 600px;*/
    width: 80%;
  }
}

.frame {
  padding: 9px;
  border-radius: 9px;
  background: var(--main-color);
  width: 100%;
  overflow: auto;
  float: left;
}

.dialogLevel0 .frame {
  margin: 0;
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}
.dialogLevel1 .frame {
  margin: 18px 0 0 18px;
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}
.dialogLevel2 .frame {
  margin: 36px 0 0 36px;
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}
.dialogLevel3 .frame {
  margin: 54px 0 0 54px;
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}

.frame .list {
  color: var(--text-color);
}
.frame .list p{
  color: white;
}

.dialog.small input.wide {
  width: 326px;
  margin: 0 0 1px 0;
}
.dialog.small input[type='file'].wide {
  color: white;
}

.dialog textarea {
  color: var(--text-color) ;
}

.dialog.small textarea.wide {
  width: 326px;
  height: 120px;
  margin: 0 0 1px 0;
}

.dialog.small select.wide {
  width: 326px;
  margin: 0 0 1px 0;
}

.dialog.small input[type='submit'].wide {
  max-width: 336px;
  flex: 1 0;
  color: var(--text-color);
}

.dialog.small input[type='submit'].wide:hover {
  cursor: pointer;
  background-color: var(--hover-red-color);
  color:white
}

.dialog.wide input.wide {
  width: 590px;
  margin: 0 0 1px 0;
}

.dialog.wide textarea.wide {
  width: calc(100% - 9px);
  /*width: 590px;*/
  height: 120px;
  margin: 4px 0 1px 0;
}

.dialog.wide input[type='submit'].wide {
  width: 600px;
}

.dialog p {
  margin: 6px;
  padding: 0;
}

.dialog .title {
  /*float: left;
  clear: both;*/
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  align-items: center;
  /*margin-bottom: 6px;*/
}

.dialog .title span {
  /*float: left;*/
  margin: 4px;
}

.dialog .title .icon {
  margin: 3px 0;
  /*float: left;*/
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
}

.dialog .row {
  float: left;
  clear: both;
  width: calc(100% - 3px);
  padding: 1px 1px 1px 2px;
}

.dialog .dialog-row {
  width: calc(100% - 12px);
  padding: 3px 6px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.dialog .dialog-row input {
  flex: 2 0;
}

.dialog .dialog-row textarea{
  flex: 2 0;
  height: 80px;
}

.dialog .dialog-row .label {
  flex: 1 0;
  min-width: 110px;
}

.dialog .dialog-row .line {
  display: flex;
  flex: 2 0;
}

/*
.dialog.small .row textarea {
  width: 324px;
  height: 80px;
}*/

.dialog .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.dialog .buttons .right-position {
  position: sticky;
  right: 0;
  bottom: 0px;
  background: var(--main-color);
  padding: 9px 0 9px 9px;
  border-radius: 9px 0 0 9px;
  display: flex;
}

.dialog .buttons .button {
  border-radius: 6px;
  padding: 3px 7px;
}

.dialog .button:hover {
  background: #cb1318;
}

/* -------------------------------------- */

.dialog.singleInputWithButtons input {
  float: left;
  width: 230px;
  margin: 6px;
}

.dialog.wide .formRow {
  /*width: 590px;*/
  padding: 4px 0px;
  align-items: center;
}

.dialog.wide .formRow label {
  width: 225px;
  flex: none;
}

.dialog.wide .formRow label.long {
  width: 325px;
}

/*.dialog.wide .formRow input {
  width: 340px;
}*/

.dialog.wide .formRow input.shortInput {
  /*width: 260px;*/
  flex: 1 0;
}

.dialog.wide .formRow input.tinyInput {
  width: 125px;
}

.dialog.wide .formRow input[type='checkbox'] {
  width: initial;
  float: left;
}

.dialog.wide .formRow select {
  max-width: unset;
}

.dialog.wide .formRow select.shortInput {
  flex: 0.3 1;
  margin-right: 5px;
  /*width: 70px;*/
}

.dialog.wide .formRow textarea {
  width: 340px;
}

.dialog .formRow .campaign {
  padding: 5px 10px;
  margin-right: 10px;
  flex: 1 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dialog .formRow .campaign:hover, .dialog .option:hover {
  background-color: #940d14;
  cursor: pointer;
}

.dialog .formRow .campaign:last-of-type, .dialog .formRow .option:last-of-type {
  margin-right: 0px;
}

.dialog .selected {
  background-color: var(--hover-red-color);
}

.checkboxDialog .option:hover {
  background-color: var(--hover-red-color);
}

.checkboxDialog .text {
  margin-left: 10px;
}

.checkboxDialog input {
  flex: none;
}

.checkboxDialog .option {
  cursor: move !important;
  background-color: inherit;
}

.checkboxDialog .dropZone {
  background-color: var(--light-green-color);
  outline: 2px dotted rgb(0, 0, 0);
  color: #000;
  outline-offset: -4px;
  margin-bottom: -5px;
  margin-top: -5px;
  padding: 3px 10px;
}

.checkboxDialog .dropZone.active {
  background-color: #ff5f5f;
}

.dialog .options {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0px;
}

.dialog .option {
  margin-right: 10px;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 3px;
}


.dialog.choosing {
  width: 25%;
  border: 3px solid var(--main-color);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.dialog.choosing .title {
  justify-content: center;
  color: var(--main-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.dialog.choosing .question {
  background-color: white;
  padding: 25px 0px;
  color: var(--text-color);
  text-align: center;
}

.dialog.choosing .confirmation-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.dialog.choosing .confirmation-buttons .button {
  cursor: pointer;
  flex: 1 0;
  padding: 5px 0px;
  text-align: center;
}

.dialog.choosing .confirmation-buttons .button.yes {
  background-color: #b6bec6;
  font-size: 16px;
}

.dialog.choosing .confirmation-buttons .button.yes:hover {
  background-color: var(--hover-green-color);
}

.dialog.choosing .confirmation-buttons .button.no {
  background-color: #d18f91;
  font-size: 16px;
}

.dialog.choosing .confirmation-buttons .button.no:hover {
  background-color: #cb1318;
}

.mixed-row {
  padding: 0px 3px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mixed-row .label {
  flex: none;
  min-width: 20%;
}

.mixed-row input {
  flex: 1 0;
  padding: 7px;
  margin-left: 10px;
}

.selection-row {
  padding: 0px 3px;
}

.selection-row .select, .selection-row .selected-items  {
  display: flex;
  align-items: center;
}

.selection-row .select .label, .selection-row .selected-items .label {
  flex: none;
  min-width: 20%;
}

.selection-row .selected {
  flex: none;
  display: flex;
}
.selection-row .input input{  
  margin-left: 0;
}
.selection-row .input input:first-child{  
  margin-left: 0;
}
.dialog .option.hidden {
  display: none;
}

.dialog select {
  color: var(--text-color);
}

.dialog ul li form {
  display: flex;
}

.dialog ul li form input:hover {
  cursor: pointer;
  background-color: var(--hover-red-color);
  color: white;
}

.dialog ul li form .errorInput {
  flex: 1 0;
  text-align: center;
  background-color: #cacaca;
  padding: 3px 0px;
  border: 1px solid #838383;
  border-radius: 2px;
}


.dialog .select select {
  flex: 1 0;
  padding: 7px;
  border: none;
  height: auto;
}

.dialog .date-range {
  flex: 1 0;
  display: flex;
}

.dialog .placeholder {
  flex: 1 0;
  padding: 2px 4px;
}

.dialog .date-selection {
  flex: 1.4 0;
  display: flex;
}
.alternative{
  display: flex;
  flex-direction: column;
}
.alternative span{
  font-size: 9px;
  font-weight: bold;
  color: grey;
  margin-right: 2px;
}

.dialog .simpleAddress.formRow .inline {
  display: flex;
  flex: 1 0;
}

.dialog .inline {
  display: flex;
  align-items: center;
}

.dialog .formRow .button {
  text-align: center;
  background: #d12038 /*#b6bec6*/;
  color: #000;
  border-radius: 2px;
  margin-left: 1px;
  padding: 0px 5px;
}

.dialog .formRow .button:hover {
  background: var(--hover-green-color);
}

.dialog .formRow .inline .flexRest {
  width: 25%;
}

.dialog .nextStep .inline{
  align-items: flex-start;
}

.dialog .nextStep .card {
  width: 100%;
}

.dialog .nextStep .card .info {
  margin: 0px 10px;
  padding: 3px 10px;
  background: #f0e5e1;
  font-size: smaller;
}

.dialog .nextStep:hover .card .info{
  color: #000;
}
.dialog .formRow.invalid {
  background: #ff9393;
  border-color: var(--invalid-color);
  color: #000;
}

.dialog.small .ant-picker{
  width: calc(100% - 6px);
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding-top: 10%;
}

.small-page .header {
  background: var(--main-color);
  padding: 0px 10px;
  border-radius: 10px 10px 0 0;
  color: var(--text-color-light);
  height: 60px;
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
}

.small-page .header .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.small-page .header h1 {
  margin-bottom: 0px;
  color: var(--text-color-light);
  margin-left: 5px;
  font-size: 25px;
  font-weight: 700;
}

.small-page .content {
  background: var(--tab-color);
  padding: 10px;
  width: calc(100% - 20px);
}

.small-page .issue {
  color: #940d14;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: center;
}

.small-page .buttons {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
}

.small-page .button {
  flex: 1 0;
  text-align: center;
  padding: 5px;
}

.small-page .button.save {
  background-color: var(--hover-red-color);
  color: var(--text-color-light);
}

.small-page .button.okay {
  background-color: var(--main-color);
  color: var(--text-color-light);
}

.small-page .button:hover {
  background-color: var(--hover-green-color);
  color: #000;
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}