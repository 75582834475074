
.callPanel {
  position: fixed;
  right: 9px;
  bottom: 9px;
  width: auto;
  height: auto;
  padding: 9px;
  border-radius: 9px;
  background: #465a64;
}

.callPanel.collapsed {
  width: 282px;
}

.callPanel.expanded {
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
  left: 100px;
}

.callPanel .icon {
  float: left;
}

.callPanel .content h3 {
  float: left;
  color: white;
  font-size: 26px;
  font-weight: bold;
  margin: 0;
}

.callPanel .content .header {
  float: left;
  width: 100%;
  color: white;
}

.callPanel .content .header .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  margin-right: 9px;
}

.callPanel .content .header .button {
  background: #b22537;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 5px;
}

.callPanel .content .header .button:hover {
  background: #cf1f37;
}

/*
.callPanel .content {
}

.callPanel .content .button {
  padding: 0 2px;
  border-radius: 6px;
}

.callPanel .content .button:hover {
  background: var(--hover-red-color);
}
*/
/*
.callPanel.expanded .content {
  width: 400px;
  height: auto;
  overflow: auto;
}

.callPanel .content .icon {
  float: left;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.callPanel .content .header > .icon {
  width: 42px;
  height: 42px;
  background-size: 42px 42px;
  margin-right: 2px;
}

.callPanel .content .header > .button.right > .icon {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}

.callPanel .content input.left {
  width: 150px;
  color: var(--text-color);
}

.callPanel .content .line {
  width: 235px;
  float: left;
}

.callPanel .content .line.withMarginTop {
  margin-top: 3px;
}

.callPanel .content .line.expanded {
  width: 400px;
}

.callPanel .content .line.expanded.withIcon {
  width: 335px;
}

.callPanel .content .line.short {
  width: 200px;
}

.callPanel .content .line > div {
  margin-top: 1px;
}

.callPanel .content .line .title {
  float: left;
  width: 80px;
  font-weight: bold;
  margin-top: 2px;
}

.callPanel .content .line .button.selected {
  background-color: #c18f5b;
}

.callPanel .content .line.short .title {
  width: 40px;
}

.callPanel .content .remotePartyInfoListTitle {
  font-weight: bold;
  margin-top: 2px;
}

.callPanel .content .remotePartyInfoList {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  padding: 9px;
  display: flex;
  flex-direction: column;
}

.callPanel .content .remotePartyInfoList .infoItem {
  padding: 6px 3px 3px 6px;
  display: flex;
  background-color: #caafc6;
  cursor: pointer;
}

.callPanel .content .remotePartyInfoList .infoItem:nth-child(even) {
  background-color: #ad90b7;
}

.callPanel .content .remotePartyInfoList .infoItem:hover {
  background-color: #559e6d;
  cursor: pointer;
}

.callPanel .content .remotePartyInfoList .infoItem .icon {
  margin-right: 5px;
  min-width: 20px;
}
@media only screen and (max-width: 1217px) {
  .callPanel {
    width: 232px ;
  }
}
*/