@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
/* generic ----------------------- */

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  color: var(--text-color);
}

*, *::before, *::after {
  box-sizing: unset !important;
}


/* VARIABLES */

:root {
  --main-color: #465a64;
  --hover-red-color: #d12038;
  --hover-green-color: #8fd1a5;
  --hover-light-color: #f3d9d9;
  --text-color: #000;
  --invalid-color: red;
  --complete-color: #21e421;
  --disabled-color: #e4e4e4;
  --tab-color: #e6e4e4;
  --light-grey-color: #dbdbdb;
  --light-red-color: #ffebeb;
  --light-orange-color: #fff0e4;
  --light-green-color: #d3edd0;
  --purple-color: #c8b0ea;
  --pink-color: #e7c2e8;
  --gold-color: #d3c392;

  --turquoise-color: #b0d5d1;
  --orange-color: orange;
  --green-color: green;
  --text-color-light: #fff;
  --box-shadow-color:#888888;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clearLeft {
  clear: left;
}

.clearRight {
  clear: right;
}

.bold {
  font-weight: bold;
}

.button {
  cursor: pointer;
}

.multiline {
  white-space: pre-line;
}

a {
  cursor: pointer;
  text-decoration: none;
  font-size: 1em;
}

/* placeholders ----------------------- */

/*.placeholder {
  float: left;
  width: 100%;
}

.placeholder .text {
  /*margin-top: 12px;*
  margin: 2px 0 0 3px;
}*/
.placeholder{
  display: flex;
  gap: 10px;
  align-items: center;
}

/* generic inputs ----------------------- */

input, select, textarea, input[type='file'] {
  padding: 0.2rem;
  border-radius: 3px;
  border: 1px solid #838383;
  height: 1rem;
  font-size: 0.9rem;
  flex: 1 0;
  /*background: rgba(255, 255, 255, 0.6);*/
}

select {
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
}

pre {
  margin: 0;
  font-family: arial;
  margin-bottom: 0 !important;
  font-style: italic;
  line-height: 1.1em;
}

pre.shiftedMessageContainer {
  margin: -6px 3px 0 3px;
}

i.shiftedMessageContainer {
  float: left;
  margin: -6px 3px 0 3px;
}

pre.separated {
  border-bottom: 1px dashed #a6a6a6;
}

input[type='submit'] {
  padding: 0;
  float: left;
  height: auto;
  padding: 3px;
}

input[type='file'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  border: 0;
  padding: 0;
  height: auto;
}

/* top menu ----------------------- */

.menuPanel {
  position: fixed;
  top: 9px;
  left: 9px;
  right: 318px;
  height: 40px;
}

.menuPanel .logo {
  position: absolute;
  width: 109px;
  height: 40px;
  top: 0;
  left: 0;
}

.menuPanel .buttons {
  position: absolute;
  bottom: 0;
  left: 110px;
  right: 280px;
}

.menuPanel .buttons .button {
  float: left;
  margin: 0 0 0 3px;
  padding: 2px 9px;
  height: 16px;
  color: var(--main-color);
}

.mainPanel .section .buttons input[type='submit'], .mainPanel .section .buttons .button {
  padding: 9px;
  background-color: var(--main-color);
  border: 0;
  border-radius: 0px;
}

.mainPanel .section .buttons input[type='submit']:nth-of-type(1), .mainPanel .section .buttons .button:nth-of-type(1) {
  border-radius: 0px 0px 0px 9px;
}

.mainPanel .section .buttons input[type='submit']:hover, .mainPanel .section .buttons .button:hover {
  background-color: var(--hover-red-color);
  cursor: pointer;
}

.menuPanel .buttons a {
  float: left;
  margin: 0 0 0 0.2rem;
  padding: 2px 7px;
  padding-bottom: 0px;
  color: var(--main-color);
  text-decoration: none;
  font-size: 1rem;
}
.menuPanel .buttons a:hover{
  color: var(--hover-red-color);
}

.menuPanel .searchBox {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--main-color);
  border-radius: 9px;
  padding: 0;
  height: 36px;
}

.menuPanel .searchBox .button {
  float: left;
  cursor: pointer;
  padding: 3px;
  margin: 3px;
  border-radius: 3px 5px 5px 3px;
  width: 24px;
  height: 24px;
}

.menuPanel .searchBox .button:hover {
  background: var(--hover-red-color);
}

.menuPanel .searchBox .icon {
  float: left;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.menuPanel .searchBox input {
  float: left;
  width: 220px;
  border-radius: 5px 3px 3px 5px;
  margin: 6px 0 6px 6px;
}



/* main panel generics -------------------------------------------- */

.mainPanel {
  position: fixed;
  top: 51px;
  left: 9px;
  right: 318px;
  bottom: 9px;
}
.mainPanelWithNavPanel{
  position: fixed;
  top: 51px;
  left: 401.5px;
  right: 318px;
  bottom: 9px;
  height: calc(100vh - 60px);
  background: var(--tab-color);
  border-radius: 0 9px 9px 0;
}

.compact .mainPanel {
  top: 9px;
  left: 9px;
  right: 9px;
  bottom: 9px;
}

.mainPanel h1 {
  padding: 0;
  margin: 0;
  float: left;
  font-size: 1.4rem;
}

.mainPanel h2 {
  padding: 0;
  margin: 0;
  /*float: left;*/
  font-size: 1.6rem;
}

.mainPanel h2.invalid {
  color: var(--invalid-color);
}

.mainPanel h3 {
  padding: 0;
  margin: 0;
  /*float: left;*/
  font-size: 1.4rem;
}

/*
.mainPanel .fullRow {
  width: 100%;
}
*/

/* header -------------------------------------------- */

.mainPanel .header {
  background: var(--main-color);
  padding: 0px 9px;
  border-radius: 0 9px 0 0;
  color: var(--text-color-light);
  height: 58px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1px;
}

.mainPanel.withTabs .header { 
  height: 89px;
  align-items: flex-start;
}

.mainPanel .header .row {
  display: flex;
  align-items: center;
  flex: 1 0;
  width: 100%;
}
.mainPanel.taskInfo .header .row,
.mainPanelWithNavPanel .header .row {
  display: flex;
  align-items: center;
  flex: 1 0;
  max-width: calc(100% - 148px);
  width:  calc(100% - 148px);
}

.mainPanel.withTabs .header .row:nth-of-type(1) {
  margin-top: 3px;
}

.mainPanel.withTabs .header .row:nth-of-type(2) {
  align-items: flex-end;
}

.mainPanel.withTabs .header .row .buttons {
  flex: none;
  display: flex;
}

.mainPanel .header h1 {
  /*max-width: 90%;*/
  overflow: hidden;
  margin-left: 0.2rem;
  color: var(--text-color-light);
  font-weight: 700;
  
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mainPanel .header .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  margin-right: 5px;
  flex: none;
}

.mainPanel .header .buttons {
  margin-right: 6px;
}

.mainPanel .header .buttons .button {
  border-radius: 6px;
}

.mainPanel .header .buttons .button:hover {
  background: var(--hover-red-color);
}

.mainPanel .header .values {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 1rem;
  font-weight: bold;
}

.mainPanel .header .values .icon {
  margin-right: 0;
}

.mainPanel .header .values .score {
  margin-right: 1rem;
}

.mainPanel .header .values .objects {
  font-size: 1.4rem;
}

.mainPanel .header .values .bigAmount {
  font-size: 1.1rem;
  margin: 0 0.6rem -0.4rem 0.3rem;
}

.mainPanel .header .values .smallAmount {
  font-size: 0.9rem;
  margin: 0 0.6rem 0 0.3rem;
}

.mainPanel .header .values .complete {
  color: var(--complete-color);
}

.mainPanel .header .tabs {
  display: flex;
  align-items: center;
}

.mainPanel .header .tabs .tab {
  border-top: 1px solid var(--tab-color);
  border-right: 1px solid var(--tab-color);
  margin: 0;
  padding: 4px 12px;
  color: var(--tab-color);
}

.mainPanel .header .tabs .tab:hover {
  background-color: var(--hover-red-color);
  cursor: pointer;
}

.mainPanel .header .tabs .tab:first-child {
  border-left: 1px solid var(--tab-color);
  margin-left: 18px;
}

.mainPanel .header .tabs .tab.active {
  background: var(--tab-color);
  color: var(--main-color);
}

/*@media only screen and (max-width: 1500px) {
  .mainPanel .header h1 {
    font-size: 24px;
  }

  .mainPanel .header span {
    font-size: 20px;
  }

  .mainPanel .header .icon {
    height: 28px;
    width: 28px;
    background-size: 28px 28px;
  }

  .mainPanel .header .values .secondary {
    font-size: 15px;
    margin-top: 3px;
  }
}*/

/* main panel scrollable ----------------------------------------- */

.mainPanel .scrollable {
  overflow-y: auto;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 58px;
  bottom: 0;
}

.mainPanel.withTabs .scrollable {
  top: 89px;
  left: 1px;
}

/* main panel content ------------------------------------ */

.mainPanel .content {
  padding: 18px;
  background: var(--tab-color);
  border-radius: 0 0 9px 0;
  overflow: auto;
}

.mainPanel.withTable .content {
  width: calc(100% - 36px);
  height: calc(100% - 36px );
}

.mainPanel .content > * {
  margin-top: 9px;
}

.mainPanel .content > .list {
  margin-top: 0;
}

.mainPanel .content > .formRow {
  margin-top: 0;
}

.mainPanel .content > *:first-child {
  margin-top: 0;
}

/*
.mainPanel .content .button {
  float: left;
  border-radius: 6px;
}
*/
.mainPanel .content .button.right {
  float: right;
}

/*
.mainPanel .content .button:hover {
  background: var(--hover-green-color);
}*/

.mainPanel .content .icon {
  float: left;
}

/* sections ------------------------------------ */

.mainPanel .section {
  border: 1px solid var(--main-color);
  padding: 9px;
  overflow: auto;
  position: relative;
  margin-bottom: 9px;
}

.mainPanel .section:last-of-type{
  margin-bottom: 0px;
}

.mainPanel .section .centered {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.mainPanel .section .centered input {
  max-width: 20%;
}

.mainPanel .section .centered input:hover {
  background-color: var(--hover-red-color);
  color:white;
  cursor: pointer;
}

.mainPanel .section .buttons {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--main-color);
  color: white;
  display: flex;
  border-radius: 0 0 0 9px;
}

.mainPanel .section .buttons .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.mainPanel .content .header-row {
  display: flex;
  align-items: center;
  /*margin-top: 9px;*/
  background-color: unset;
  padding: 0px;
}

.mainPanel .content .header-row .right {
  margin-left: auto;
}

.mainPanel .content .header-row .right > .button {
  margin-right: 0px;
  margin-left: 3px;
  float: left;
}


.mainPanel .content .header-row .button {
  margin-right: 5px;
}

.mainPanel .content .header-row .button:hover {
  background-color: var(--hover-green-color);
}

.mainPanel .content .header-row .buttons {
  display: flex;
  margin-left: auto;
}

.mainPanel .content .header-row h2 {
  font-weight: 700;
}

.mainPanel .section .subsection {
  background: var(--light-grey-color);
  padding: 9px;
  overflow: auto;
  /*margin-top: 9px;*/
}

.mainPanel .section .subsection .header-row {
  display: flex;
  align-items: center;
  /*margin-top: 9px;*/
  background-color: unset;
  padding: 0px;
}

.mainPanel .section .subsection .header-row .button {
  margin-left: auto;
  margin-right: 5px;
}

.mainPanel .section .subsection .header-row .button:hover {
  background-color: var(--hover-green-color);
}

.mainPanel .section .subsection .header-row h3{
  font-weight: 700;
  color: var(--main-color);
}

.mainPanel .section .subsection ~ .subsection {
  margin-top: 9px;
}

.mainPanel .section .content {
  /*columns: 3 auto;*/
  column-width: 500px;
  justify-content: flex-start;
  align-items: center;
  clear: both;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: none;
  overflow-x: hidden;
}

/* this formatting was not satisfactory */
/*
@media only screen and (max-width: 1700px) {
  .mainPanel .section .content {
    columns: 2 auto;

  } 
}

@media only screen and (max-width: 1400px) {
  .mainPanel .section .content {
    columns: 1 auto;
  } 
}
*/

.mainPanel .section .contentCompact {
  column-width: 200px;
  clear: both;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.ant-picker-time-panel-column {
  overflow-x: hidden;
}

.ant-picker {
  height: 15px;
  padding: 4px 3px !important;
  border: 1px solid #838383 !important;
}

.clientRating {
  /*position: absolute;
  right: 25px;
  top: 10px;*/
  margin-left: 10px;
  font-size: 30px;
}
.header__buttons{
  position: absolute;
  top: 0;
  right: 0;
  flex: none;  
  display: flex;
  align-items: flex-end;
  text-align: right;
  flex-direction: column; 
  gap:2px;  
}
.header__buttons button{
  max-height: 17px;
  padding: 2px 5px;
  flex: 1 0;
  background-color: var(--hover-red-color);
  color: var(--text-color-light);
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: 138px;
  font-size: 12px;
}
.header__buttons button:hover{
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}
.header__buttons button:first-child{
  border-radius: 0 9px 0 0;
}
.header__buttons button.disabled{
  background-color: #999999;
  cursor: default;
  box-shadow: none;
}
.header__buttons button.disabled:hover{
  width: 138px;
}
.header__buttons button:hover{
  width: 180px;
}

.clientRating.green,
.clientRatingIcon.green {
  color: #21e421;
}

.clientRating.yellow,
.clientRatingIcon.yellow {
  color: #e1e421;
}

.clientRating.orange,
.clientRatingIcon.orange {
  color: #e4a621;
}

.clientRating.red,
.clientRatingIcon.red{
  color: #e42121;
  background-color:#465a64;
}

.promisedAmount {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 25px;
}

.list li .promise.row.isCancelled {
  background-color: #c9c9c9;
}

.list li .promise.row.isFinished {
  background-color: #ffa7a7;
}

.list li .promise.row.isSuccessful {
  background-color: #a1ff9e;
}




.subMenu{
  width: 100%;
  background-color: #465a64;
}
.subMenu .tabs {
  display: flex;
  align-items: center;
}

.subMenu .tabs .tab {
  border-top: 1px solid var(--tab-color);
  border-right: 1px solid var(--tab-color);
  margin: 0;
  padding: 4px 12px;
  color: var(--tab-color);
  flex: 1 0;
}

.subMenu .tabs .tab:hover {
  background-color: var(--hover-red-color);
  cursor: pointer;
}

.subMenu .tabs .tab:first-child {
  border-left: 1px solid var(--tab-color);
}

.subMenu .tabs .tab.active {
  background: var(--tab-color);
  color: var(--hover-red-color);
}
.header--flex{
  display: flex;
  flex-direction: column;
}
/*.mainPanel .header--flex div h1{
  max-width: 650px;
}*/
.header--flex span{
  margin-left: 0.2rem;
}
.header--flex div:first-child{
  margin-left: 0;
}
.header--flex .header--flex--info div{
  margin-left: calc(41px + 0.2rem) ;
}
.header--flex div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.redInput{
  background-color: var(--light-red-color);
  border: 1px solid var(--invalid-color);
}
.orangeInput{
  background-color: var( --light-orange-color);
  border: 1px solid var(--orange-color);
}
.flex--lustration{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.flex__placeholder{
  display: flex;
  gap:5px;
  align-items: center;
  padding: 18px;
  background: var(--tab-color);
  border-radius: 0 0 9px 0;
}
.flex__placeholder div{
  align-self: center;
}

.mainPanel.withTabs .error {
  display: flex;
  align-items: center;
}
.displayNone{
  display: none;
}
.mainPanel .header .row .header--flex{
  /*max-width: calc(100% - 150px);*/
  max-width: 80%;
}

@media only screen and (max-width: 1700px) {
  /*.mainPanel .header--flex div h1 {
    max-width: 600px;
  }*/
  .mainPanelWithNavPanel.withTabs .header .row .values {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1650px) {
  .mainPanelWithNavPanel .header .row .header--flex{
    max-width:100%;
  }
  .mainPanelWithNavPanel .header .row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .mainPanelWithNavPanel.withTabs .header .row .values {
    margin-left: 0;
    font-size: 15px;
  }
  .mainPanelWithNavPanel .header span {
    font-size: 15px;
  }
  .mainPanelWithNavPanel .header h1 {
    font-size: 21px;
  }
  .mainPanelWithNavPanel .header .icon {
    height: 27px;
    width: 27px;
    background-size: 27px 27px;
  }

}

@media only screen and (max-width: 1217px) {
  .menuPanel .buttons a {
    font-size: 0.8rem;
    padding: 0px 5px;
  }

  .menuPanel .searchBox input {
    width: 100px;
  }

  .menuPanel .buttons {
    right: 150px;
  }

  .mainPanel {
    right: 268px;
  }
  .header--flex div {
    flex-wrap: nowrap;
  }

  .mainPanelWithNavPanel .header h1 {
    font-size: 20px;
  }
  .mainPanelWithNavPanel .header span {
    font-size: 15px;
  }
  .mainPanelWithNavPanel .header .row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .mainPanelWithNavPanel.withTabs .header .row .values {
    font-size: 15px;
    margin-left: 0;
  }
  .mainPanelWithNavPanel .header .icon {
    height: 25px;
    width: 25px;
    background-size: 25px 25px;
  }
  .menuPanel .searchBox {
    right: -50px;
  }
  .menuPanel .buttons {
    right: 100px;
  }
}

.dialog.small img{
  width: 354px;
}

.ant-picker-content td, .ant-picker-content th {
  z-index: 2;
}