
.taskPanel {
  position: fixed;
  top: 9px;
  right: 9px;
  bottom: 9px;
  width: 300px;
  background: var(--tab-color);
  border-radius: 9px;
}

.taskPanel.withCallPanel {
  bottom: 55px;
}

.taskPanel .top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 24px;
  padding: 6px;
  background: #465a64;
  border-radius: 9px 9px 0 0;
  display: flex;
}

.taskPanel .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 9px;
  background: #465a64;
  border-radius: 0 0 9px 9px;
}

.taskPanel .scrollable {
  position: absolute;
  top: 36px;
  bottom: 9px;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 9px;
  display: flex;
  flex-direction: column;
}

.taskPanel .scrollable h3 {
  margin: 0;
  font-size: 1.2em;
}
/*
.taskPanel .scrollable ul {
  padding: 0;
  margin: 0;
  list-style: none;
}*/

.taskPanel .scrollable .flag {
  padding: 6px 3px 3px 6px;
  display: flex;
  background-color: white;
  cursor: pointer;
  margin-bottom: 3px;
  border: 1px solid var(--main-color);
}

/*.taskPanel .scrollable .flag:nth-child(even) {
  background-color: #d7cbdb;
}*/

.taskPanel .scrollable .task:not(.icon.task) {
  padding: 6px 3px 3px 6px;
  display: flex;
  background-color: white;
  cursor: pointer;
  margin-bottom: 3px;
  border: 1px solid var(--hover-red-color)
}

/*.taskPanel .scrollable .task:not(.icon.task):nth-child(odd) {
  background-color: #ecb8b9;
}*/

.taskPanel .scrollable .flag:hover,
.taskPanel .scrollable .task:not(.icon.task):hover {
  background: var(--hover-red-color);
  color: white;
}
.taskPanel .scrollable .flag:hover .icon,
.taskPanel .scrollable .task:not(.icon.task):hover  .icon{
  -webkit-filter: invert(100%);
}
.taskPanel .scrollable .flag .icon,
 .taskPanel .scrollable .task .icon {
  margin-right: 5px;
  min-width: 20px;
}

.taskPanel .scrollable .flag .count,
.taskPanel .scrollable .task .count {
  flex: none;
  margin-left: auto;
}

/*
.taskPanel .scrollable ul li:nth-child(even):hover {
  background: #8fd1a5;
}*/

.taskPanel .scrollable ul li.flag {
  background: #c0a9bd;
}
.taskPanel .scrollable ul li.flag:nth-child(even) {
  background: #d7cbdb;
}
.taskPanel .scrollable ul li.flag:hover {
  background: #cfb53b7d;
}
.taskPanel .scrollable ul li.flag:nth-child(even):hover {
  background: #cfb53b7d;
}

.taskPanel .scrollable ul li > div {
  float: left;
  margin: 0 3px 3px 0;
}

.taskPanel .scrollable ul li .name {
  float: left;
  width: 190px;
}

.taskPanel .scrollable ul li .count {
  float: right;
  width: 35px;
  text-align: end;
}

.taskPanel .header-select {
  width: calc(100% - 0.4rem);
}

.select-with-dropdown {
  cursor: pointer;
  flex: 1 0;
  display: flex;
  user-select: none; 
  margin-right: 5px;
  align-items: center;
}

.select-with-dropdown:last-of-type {
  margin-right: 0px;
}

.select-with-dropdown .values {
  background-color: #ffffff;
  position: absolute;
  top: 30px;
  left: 5px;
  width: 96%;
  z-index: 3;
  border: 1px solid var(--text-color);
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
}

.select-with-dropdown .values .value {
  padding: 2px 5px;
}

.select-with-dropdown .values .value:hover {
  background-color: var(--hover-red-color);
  color: white;
}

.select-with-dropdown .selected {
  z-index: 3;
  position: relative;
  background-color: var(--light-green-color);;
}

.select-with-dropdown .number-of-selected {
  position: relative;
  background-color: var(--tab-color);
  font-size: 10px;
  font-weight: 800;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.select-with-dropdown .name {
  padding: 0px 5px;
  flex: 1 0;
  font-weight: 600;
  background-color: var(--hover-red-color);
  color: var(--text-color-light);
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
}

.select-with-dropdown:nth-of-type(1) .name {
  border-top-left-radius: 5px;
}

.select-with-dropdown:last-of-type .name,
.select-with-dropdown:last-of-type .number-of-selected{
  border-top-right-radius: 5px;
}
.select-with-dropdown .hidden {
  display: none;
}

.select-with-dropdown input {
  width: 97%;
  border-radius: 0;
  border: none;
  padding: 6px 4px;
  border-bottom: 1px solid;
}

.select-with-dropdown .click-catcher {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media only screen and (max-width: 1217px) {
  .taskPanel {
    width: 250px;
  }
  .select-with-dropdown{
    margin-right: 3px;
    font-size: 13px;
  }
  .taskPanel .scrollable{    
    font-size: 13px;
  }
}