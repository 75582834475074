/* form generics -------------- */

.formRow:hover {
  background: rgba(121, 107, 107, 0.3);
}

.formRow .text  {
  margin: 0.2rem 0.4rem;
}

.formRow {
  /*float: left;*/
  /*width: 500px;*/
  overflow: auto;
  display: flex;
  page-break-inside: avoid;

  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px;
}

.formRow.center {
  align-items: center;
}

/* full form displaying title: value -------------- */

.fullForm {
  column-width: 500px;
  clear: both;
}

.fullForm .formColumn {
  width: 100%;
  overflow: auto;
}

.formRow label {
  /*float: left;
  width: 200px;*/
  /*margin: 3px 0 2px 0;*/
  flex: 0.75 0; 
  max-width: 40%;
}

.fullForm .formRow label  {
  float: left;
  width: 30%;
  margin: 0.2rem 0.4rem;
}


.formRow .inputReplacement {
  /*float: left;
  margin: 3px 0 2px 0;*/
  flex: 1 0;
}

/*
.formRow.single {
  width: 300px;
}

.formRow.fullLength {
  width: 100%;
}
*/


/*
.formRow .checkContainer {
  float: left;
  width: 280px;
}
*/

/* full form inputs -------------- */

.fullForm .formRow .formInput {
  /*float: left;
  width: 290px;*/
  flex: 1 0; 
  /*max-width: calc(57% - 5px);*/
}

.fullForm .formRow .inputReplacement  {
  float: left;
  margin-top: 0.2rem;
}

.fullForm .formRow textarea {
  height: 5rem;
}

.fullForm .formRow input[type='checkbox'] {
  width: initial;
}

/* line form displaying only values formatted to be used in lists -------------- */

.lineForm {
  display: flex;
  flex-wrap: wrap;
}

.lineForm > * {
  flex: 1 0 16ch;
  min-width: 16ch;
}

.lineForm > .short {
  flex: 0.25 0 4ch;
  min-width: 4ch;
}

.lineForm > .medium {
  flex: 0.5 0 8ch;
  min-width: 8ch;
}

.lineForm > .inputReplacement  {
  margin-top: 0.2rem;
}

.lineForm > input:hover, .lineForm > select:hover, .lineForm > textarea:hover {
  background: rgba(226, 226, 226, 0.6);
}


.formRow select.formInput {
  flex: 1 0;
  max-width: 58%;
}

.formRow .shortInput {
  /*float: left;
  width: 190px;*/
  flex: 1 0;
  /*max-width: calc(57% - 27px)*/
}

.formRow select.shortInput {
  flex: 1 0;
  /*max-width: calc(57% - 22px)*/
}

.formRow .timeInput {
  width: 40px;
  flex: 0;
}

.formRow .value-with-unit {
  flex: 1 0;
  display: flex;
  /*max-width: calc(57% - 27px);*/
}

.formRow .value-with-unit.input{
  flex: 1 0;
  display: flex;
  /*min-width: 58%;*/
}

.formRow .value-with-unit.input .tinyInput{
  max-width: 30%;
}

.formRow .value-with-unit div, .formRow .value-with-unit input {
  max-width: unset;
  width: 100%;
}


.formRow .tinyInput {
  /*float: left;
  width: 100px;*/
  margin-left: 10px;
}


.formRow select {
  flex: 1 0;
}

.formRow textarea {
  float: left;
  width: 290px;
  height: 60px;
}

.formRow.fullLength input {
  width: 99%;
}

.formRow.fullLength textarea {
  width: 99%;
  height: 200px;
}

.formRow .invalid {
  background: #ff9393;
  border-color: var(--invalid-color);
}
.content .formRow.invalid {
  background: #ff9393;
  border-color: var(--invalid-color);
}

.formRow div.invalid {
  border: 1px solid var(--invalid-color);
  padding: 2px;
}

.row .invalid {
  background: #ff9393;
  border-color: var(--invalid-color);
}

.row div.invalid {
  border: 1px solid var(--invalid-color);
  padding: 2px;
}

.formRow .button {
  float: left;
  margin: 2px 1px;
  border-radius: 3px;
}

.formRow.highlighted {
  background: var(--light-red-color);
}

.formRow.yellow {
  background: #faf27d;
}

@media only screen and (max-width: 1400px) {
  .formRow.textArea.big label {
    min-width: 19.5%;
  }
}

@media only screen and (max-width: 1100px) {
  .formRow.textArea.big label {
    min-width: 40%;
  }
}

.formRow.textArea.big textarea, .formRow.textArea.big .inputReplacement {
  flex: 5.05 0;
}

.special-content {
  display: flex;
  padding: 1px;
}

.special-content .formRow{
  flex: 1 0;
}

.content .email-section {
  display: flex;
  page-break-inside: avoid;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px;
}

.email-section .title {
  flex: 0.75 0;
  max-width: 40%;
}

.email-section .title.open {
  flex: 0.75 0;
  max-width: 30%;
}

.special-content .formRow:nth-of-type(2) {
  margin-left: 13px;
}

.email-section .email-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.email-section .email-row {
  display: flex;
}

.email-section .email-row input {
  flex: 1;
}

.email-section .add-email-button {
  flex: none;
}

.add-email-button:hover {
  background: #8fd1a5;
  cursor: pointer;
}

.mail-subscription {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 2px 10px;
  background-color: #ecb8b9;
}

.subscription-list {
  height: 250px;
  width: 100%;
  overflow-y: scroll;
}

.subscription-list .item{
  margin-bottom: 10px;
}

.mail-subscription .row {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mail-subscription .row label {
  flex: 1 0;
}

.mail-subscription .row input {
  flex: none;
}

.mail-subscription .row .example {
  font-size: 10px;
  font-style: italic;
  flex: 1;
  margin-left: 5px;
}

.email-row .icon.edit {
  margin: 0px 10px
}

.section-devider {
  width: 15%;
  height: 1px;
  margin: 10px 0px;
  margin-left: -10px;
  background-color: #465a64;
}

.section .divider {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.divider .a {
  flex: none;
  min-width: 10%;
  height: 2px;
  background-color: #0000000e;
}

.divider .text {
  flex: none;
  margin: 0px 20px;
  letter-spacing: 1.5px;
  font-size: smaller;
  color: #a3a3a3;
}

.divider .b {
  flex: 1;
  height: 2px;
  background-color: #0000000e;
}

button.formButton {
  flex: 1 0;
  border: unset;
}

button.formButton:hover {
  cursor: pointer;
  background-color: var(--hover-light-color);
}

.guide .guide-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guide .actual-page {
  font-size: small;
}

.guide .instructions {
  background-color: #c6c6c6;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.guide .instructions p {
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 1.2px;  
  margin-bottom: 0px;
  font-size: smaller;
}

.guide .previous {
  background-color: #d3cd77;
  padding: 5px 15px;
}

.guide .previous:hover {
  background-color: #d3c945;
  cursor: pointer;
}

.guide p {
  font-size: large;
}

.guide .a {
  padding: 5px 15px;
  background-color: #8fd1a5;
}

.guide .a:hover {
  cursor: pointer;
  background-color: #49cf75;
}

.guide .b {
  padding: 5px 15px;
  background-color: #ff9393;
}

.guide .options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.guide .b:hover {
  cursor: pointer;
  background-color: #fa6a6a;
}

.list li .row .simpleAddress {
  display: flex;
  align-items: center;
  flex: 1 0;
}

.list li .row .simpleAddress label{
  margin-right: 5px;
}

.list li .row .simpleAddress input{
  margin-left: 5px;
  max-width: 150px;
  width: 25%;
}

.formRow .password-row {
  display: flex;
  flex: 1 0;
  align-items: center;
  position: relative;
}

.formRow .password-row input {
  padding-right: 30px;
}

.formRow .password-row .icon {
  cursor: pointer;
  position: absolute;
  right: 5px;
}