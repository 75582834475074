.navPanel{
  position: fixed;
  top: 51px;
  left: 9px;
  bottom: 9px;
  width: 390px;
  background: var(--tab-color);
  border-radius: 9px 0 0 9px;
  border-right: 1.5px solid var(--main-color);
}
.navPanel--withPadding{
  padding: 9px 0px 0px 9px;
}
.navPanel--scrollable{
  width: 381px;
  height: calc(100vh - 103px);
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}
.navPanel--scrollable.withActivePanel{
  height: calc(100vh - 133px);
}
.activePanel{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: var(--hover-red-color);
  color: var(--text-color-light);
  height: 30px;
  border-radius: 0 0 5px 5px;
}
.activePanel:hover{
  cursor: pointer;
}
.navPanel__object__icons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.navPanel__object--flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px
}
.navPanel__object--flex .navPanel__object{
  flex-grow: 1;
}
.row.withHover button{ 
  max-height: 21px;
  padding: 0px 5px;
  flex: 1 0;
  font-weight: 500;
  background-color: var(--hover-red-color);
  color: var(--text-color-light);
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: 138px;
  text-align: right;
}

.navPanel--scrollable div{
  margin-right: 2px;
}
.navPanel__object {
  border: 1px solid var(--main-color);
  border-radius: 5px 5px 5px 5px ;
  text-align: left;
  margin-bottom: 5px;
  background-color: white;
}
.navPanel__object__details{
  display: flex;
  flex-direction: row;
  margin: 0 0 0 20px;
  font-size: 13px;
}
.detail .navPanel__object__details{  
  padding-bottom: 3px;
}
.navPanel__object__details div{
  flex: 1 0 100px;
}
.navPanel__object .title{
  font-size: 9px;
  color: grey;
  margin-right: 2px;
}
.ratingIcon{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  min-width: 6px;
}
.detailHidden{
  display: none;
}
.detail {
  margin-left: 10px;
}
.detail--statutaries{
  margin-left: 20px;
}
.navPanel__object__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 0px;
}
.navPanel__object__header div{
  max-width: 225px;
}

.navPanel__object__header span { margin: 0 0 0 -4px; padding: 0; }
.navPanel__object__header .redText { color: red; }
.navPanel__object__header .yellowText { color: #ff8b36; }
.navPanel__object__header .greenText { color: green; }
.navPanel__object__header .greyText { color: #bdbdbd; }

.flex{
  display: flex;
  gap: 5px;
  align-items: center;
}
.flex div{
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}
.navPanel select{
  width: calc(100% - 9px);
  cursor: pointer;
  margin-bottom: 5px;
}
.licenseNumber{
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.green{
  background-color: var(--green-color);
  color: var(--green-color);
}
.orange{
  background-color: var(--orange-color);
  color:var(--orange-color);
}
.red{
  background-color: var(--invalid-color);
  color: var(--invalid-color);
}
.navPanel__object--adressee.greenSelected,
.navPanel__object.greenSelected{
  background-color: var(--light-green-color);
  border: 1px solid var(--green-color);
  font-weight: bold;
  box-shadow: 1px 1px 5px var(--box-shadow-color);
  border-left: 7px solid var(--green-color);
  border-radius: 5px;
  margin-right: 0px;
}
.navPanel__object--adressee.redSelected,
.navPanel__object.redSelected{
  background-color: var(--light-red-color);
  border: 1px solid var(--invalid-color);
  font-weight: bold;
  box-shadow: 1px 1px 5px var(--box-shadow-color);
  border-left: 7px solid var(--invalid-color);
  border-radius: 5px;
  margin-right: 0px;
}
.navPanel__object--adressee.orangeSelected,
.navPanel__object.orangeSelected{
  background-color: var( --light-orange-color);
  border: 1px solid var(--orange-color);
  font-weight: bold;
  box-shadow: 1px 1px 5px var(--box-shadow-color);
  border-left: 7px solid var(--orange-color);
  border-radius: 5px;
  margin-right: 0px;
}
.navPanel__object--adressee.addresseeSelected{
  background-color: var(--light-green-color);
  border: 1px solid #cbcbcb;
  font-weight: bold;
  box-shadow: 1px 1px 5px var(--box-shadow-color);
  border-radius: 5px;
  margin-right: 0px;
}
.navPanel .greenBorder{
  border-left: 7px solid var(--green-color);
  border-radius: 5px;
}
.navPanel .redBorder{
  border-left: 7px solid var(--invalid-color);
  border-radius: 5px;
}
.navPanel .orangeBorder{
  border-left: 7px solid var(--orange-color);
  border-radius: 5px;
}
.plusDetail{
  width: 100%;
  height: 13px;
  background-color: var(--main-color);
  border-top: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;  
  border-bottom-right-radius: 3px;
  color: var(--text-color-light);
  margin-right: 0px;
}
.plusDetail:hover{
  background-color: var(--hover-red-color);
}
.detail .allowView.navPanel__object:hover{
  background-color: var(--tab-color);
  cursor: pointer;
  box-shadow: 0px 0px 15px rgb(81 57 57);
}
.allowView.navPanel__object:hover,
.allowView.navPanel__object--adressee:hover,
.navPanel__object--adressee:hover{
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgb(81 57 57);
}
.navPanel__object.adressee:hover{
  background-color: white;
  cursor: default;
}
.detail  .navPanel__object.statutaties:hover{
  background-color: white;
  cursor: default;
}
.activeButtons{
  width: 100%;
  background-color: #d12038;
}
.dialog__header .flex{
  justify-content: space-between;
  font-weight: bold;
}
.dialog__header .flex div,
.dialog__details div{
  display: flex;
  gap:5px
}
.dialog__details .title{
  font-size: 9.5px;
  font-weight: bold;
  color: white;
  margin-right: 2px;
  width: 20px;
}
.dialog__details .flex{
  align-items: flex-end
}
.dialog__details{
  margin-bottom: 10px;
}
