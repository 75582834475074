/* list header ----------------------------------- */

.listHeader{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
.listHeader div{
  display: flex;
  gap: 10px;
}
.listHeader div div{
  gap: 3px;
}

.listHeader .pageSelector {
  float: left;
  border-radius: 3px 3px 0 0;
  margin-right: -3px;
  color: var(--main-color);
}

.listHeader .pageSelector {
  cursor: pointer;
}

.listHeader .pageSelector:hover {
  background: var(--hover-red-color);
  color:white;
}

.listHeader .pageSelector.active {
  cursor: pointer;
  color: #a1a1a1;
}

.listHeader .block {
  background-color: var(--main-color);
  color: white;
  border-radius: 6px;
}

.listHeader .block span {
  padding: 2px 6px;
}

.listHeader .block .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  align-self: center;
}

.listHeader input {
  background: rgba(255, 255, 255, 0.3);
  margin-top: 1px;
  margin-bottom: 1px;
}

.listHeader select {
  background: rgba(255, 255, 255, 0.3);
  margin-top: 1px;
  margin-bottom: 1px;
}

.listHeader textarea {
  background: rgba(255, 255, 255, 0.3);
  margin-top: 1px;
  margin-bottom: 1px;
}

.listHeader.highlighted {
  background-color: #c26062;
}

/* list frame ----------------------------------- */

.list {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
}

.list.spacedTop {
  margin-top: 6px;
}

.list .spaced {
  padding: 3px 3% 9px 3%;
  float: left;
  width: 94%;
  margin: 0;
  background: rgba(145, 6, 10, 0.05);
}

.list .spacedTop {
  padding: 18px 18px 3px 18px;
  float: left;
  margin: 0;
}

.list li {
  overflow: auto;
}
.list.level1 li .row {
  background-color: #bfbfbf;
}
.list li .row.inactive {
  background: #bfbfbf !important;
  cursor: default !important;
  color: var(--text-color) !important
}
.list li .row.inactive .icon{
  -webkit-filter: invert(0) !important;
}
/*list li > .row */
.list li .row {
  /*background:  #d18f91; #ffebeb #dbcfcb*/
  background: white;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
li .row.contact  {
  background-color: var(--tab-color);
}
/*.list li:nth-child(even) > .row  {
 background: #dbcfcb
}*/

.list li > .row.orangeBorder{
  background: var( --light-orange-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.orangeBorder {
 background: var( --light-orange-color);
 margin-bottom: 2px;
}
.list li > .row.purpleBorder {
  background: var( --purple-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.purpleBorder {
 background: var( --purple-color);
 margin-bottom: 2px;
}
.list li > .row.pinkBorder {
  background: var( --pink-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.pinkBorder {
 background: var( --pink-color);
 margin-bottom: 2px;
}
.list li > .row.goldBorder {
  background: var( --gold-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.goldBorder {
 background: var( --gold-color);
 margin-bottom: 2px;
}
.list li > .row.turquoiseBorder {
  background: var( --turquoise-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.turquoiseBorder {
 background: var( --turquoise-color);
 margin-bottom: 2px;
}
.list li > .row.redBorder{
  background: var(--light-red-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.redBorder {
 background: var(--light-red-color);
 margin-bottom: 2px;
}
.list li > .row.greenBorder{
  background: var(--light-green-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.list li:nth-child(even) > .row.greenBorder {
 background: var(--light-green-color);
 margin-bottom: 2px;
}

/*.dialog .list li .row {
  /*background:  #d18f91; #dbcfcb #dbcfcb
  background: white;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1px;
  /*flex-flow: row nowrap;
  align-items: flex-start;*/

/*.dialog .list li:nth-child(even) > .row  {
 background: #dbcfcb;
}*/

/* list buttons --------------------------- */

.list li .button {
  padding: 3px;
  align-self: center;
}

.list li .button ~ .button {
  margin-left: 3px;
}

.list li .button:hover {
  background: #d4d4d4;
  border-radius: 5px;
  /*height: 20px;*/
}


/* list row layout --------------------------- */

.list li > .row .icons {
  flex: 0 0 auto;
  padding: 2px;
}

.list li > .row .icons .icon {
  margin: 1px;
}

.list li > .row .buttonIcons {
  flex: 0 0 auto;
}

.list li > .row .buttonIcons .button {
  padding: 3px;
}

.list li > .row .stretched {
  flex: 1 1 auto;
}

.list li .row.rowHeader {
  background: var(--main-color);
  color: white;
}

.list li .row.rowHeader.withRating {
  padding-left: 17px;
}

.list.level1 li .row.rowHeader {
  color: white;
  background-color: var(--main-color);
}

.list li .row .inline{
  display: flex;
  flex: 1 0;
}

.list li .row .icon-button{
  padding: 3px;
  margin-top: -3px;
  margin-left: 5px;
}

/*.list.level1 li .row {
  background: #dbcfcb;/*#b6bec6;
  margin-bottom: 2px;
}*/

.list.level1 li .row.multiple-columns .row{
  background-color: #bfbfbf;
}
.list.level1 li .row.multiple-columns.redBorder > div,
.list li .row.multiple-columns.redBorder > div{
  background-color: var(--light-red-color);
}
.list.level1 li .row.multiple-columns.orangeBorder > div,
.list li .row.multiple-columns.orangeBorder > div{
  background-color: var( --light-orange-color);
}
.list.level1 li .row.multiple-columns.purpleBorder > div,
.list li .row.multiple-columns.purpleBorder > div{
  background-color: var( --purple-color);
}
.list.level1 li .row.multiple-columns.pinkBorder > div,
.list li .row.multiple-columns.pinkBorder > div{
  background-color: var( --pink-color);
}
.list.level1 li .row.multiple-columns.goldBorder > div,
.list li .row.multiple-columns.goldBorder > div{
  background-color: var( --gold-color);
}
.list.level1 li .row.multiple-columns.turquoiseBorder > div,
.list li .row.multiple-columns.turquoiseBorder > div{
  background-color: var( --turquoise-color);
}
.list.level1 li .row.multiple-columns.greenBorder > div,
.list li .row.multiple-columns.greenBorder > div{
  background-color: var(--light-green-color);
}
.list.level1 li .row.multiple-columns.withHover.redBorder:hover div,
.list.level1 li .row.multiple-columns.withHover.orangeBorder:hover div,
.list.level1 li .row.multiple-columns.withHover.purpleBorder:hover div,
.list.level1 li .row.multiple-columns.withHover.turquoiseBorder:hover div,
.list.level1 li .row.multiple-columns.withHover.greenBorder:hover div,
.list li .row.multiple-columns.withHover.redBorder:hover div,
.list li .row.multiple-columns.withHover.orangeBorder:hover div,
.list li .row.multiple-columns.withHover.purpleBorder:hover div,
.list li .row.multiple-columns.withHover.turquoiseBorder:hover div,
.list li .row.multiple-columns.withHover.greenBorder:hover div{
  background-color: var(--hover-red-color);
  color: white;
}
.list li .row.multiple-columns.withHover.redBorder:hover .icon,
.list li .row.multiple-columns.withHover.orangeBorder:hover .icon,
.list li .row.multiple-columns.withHover.purpleBorder:hover .icon,
.list li .row.multiple-columns.withHover.turquoiseBorder:hover .icon,
.list li .row.multiple-columns.withHover.greenBorder:hover .icon{
  -webkit-filter: invert(0%);
  background-color: inherit;
}

.list.level1 li .redBorder > div{
  background: var(--light-red-color);
}
.list.level1 li .orangeBorder > div{
  background: var( --light-orange-color);
}
.list.level1 li .purpleBorder > div{
  background: var( --purple-color);
}
.list.level1 li .pinkBorder > div{
  background: var( --pink-color);
}
.list.level1 li .goldBorder > div{
  background: var( --gold-color);
}
.list.level1 li .turquoiseBorder > div{
  background: var( --turquoise-color);
}
.list.level1 li .greenBorder > div{
  background: var(--light-green-color);
}
.list.level1 li .row.orangeBorder {
  background: var( --light-orange-color);
  margin-bottom: 2px;
}

/*.list.level1 li:nth-child(even) > .row, .list .list li:nth-child(even) > div > .row {
  background: #ecb8b9;
}

.list.level1 li .row.important1 {
  background: #b6bec6;/*#b6bec6;
}*/
.padding7{
  padding-left: 7px;
}
.list li .row.rowHeader.level2 {
  color: var(--text-color);
  background: #b6bec6;
}

.list li .row .score-bar {
  padding: 0;
  width: 5px;
  display: flex;
  align-self: stretch;
}

.list li .row .score-bar.green {
  background-color: #21e24b;
}

.list li .row .score-bar.orange {
  background-color: #ffee00;
}

.list li .row .score-bar.red {
  background-color: #ff0000;
}

.list li .row.multiple-columns {
  flex-direction: column;
}

.list li .row.multiple-columns .row {
  background: white;
  display: flex;
  width: calc(100% - 12px);
  height: unset;
}

/*icon*/
.list .row.multiple-columns .row > div:nth-of-type(1) {
  padding-left: 0;
}

.list li .row.future {
  background: #c0bda9;
}

.list .list li .row {
  background: #c7cace;/*#b6bec6;*/
  margin-bottom: 1px;
}

.list .list li:nth-child(even) > .row, .list .list li:nth-child(even) > div > .row {
  background: #c7cace;
}

.list li .row.withHover {
  cursor: pointer;
  display: flex;
}

.list li .withHover:hover {
  background: var(--hover-red-color);
  color: white;
  cursor: pointer;
}

.list li .row.withHover:hover {
  background: var(--hover-red-color);
  color: white;
}
.list li .row.withHover:hover .icon {
  -webkit-filter: invert(100%);
}
.list li .row.withHover:hover .button:hover .icon{
  -webkit-filter: invert(0%);
}

.list .row > div:not(.ratingIcon) {
  float: left;
  padding: 3px 6px;
  min-height: 19px;
}

.list .row .ratingIcon {
  margin: 10px 0px 0px 5px;
}

.list .row .icons.contacts {
  padding: 2px 6px;
  display: flex;
  width: calc(100% - 12px );
  justify-content: space-between;
  flex-wrap: nowrap;
}

.list .contact-buttons {
  padding: 2px 6px;
  display: flex;
  flex: none;
}
.contacts--flex{
  display: flex;
  gap:5px;
  align-items: center;
  flex-direction: row;
}

.list .contact-buttons .button,
.rowAccessories .button{
  background-color: #8ce18c;
  padding: 0px 10px;
  border-radius: 3px;
}

.list .contact-buttons .button:hover{
  background-color: var(--hover-red-color);
  color: white;
}
.list .contact-buttons .button:hover .icon{
  -webkit-filter: invert(100%);
}
.list .row .icons.contacts .inputReplacement{
  margin-left: 5px;
  background-color: var(--light-grey-color);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  color: var(--hover-red-color);
}

.list .row .icons.contacts select {
  min-width: 140px;
}

/*.list li:nth-of-type(even) .row .icons.contacts .inputReplacement{
  background-color: #f5c0c0;
}

.list .list li:nth-of-type(odd) .row .icons.contacts .inputReplacement{
  background-color: #aeb5d6;
}

.list .list li:nth-of-type(even) .row .icons.contacts .inputReplacement{
  background-color: #c2c8de;
}*/

.list .row .contacts + .stretched  {
  padding: 2px 6px;
}

.list .row .contacts + .stretched .newLine {
  margin: 0px;
}

.list .row .contacts + .stretched .inputReplacement {
  margin: 0px;
}

.list .row.rowHeader > div {
  overflow: hidden;
}

.list .row.rowHeader > .highlighted {
  background: #c26062;
  cursor: grab;
}

.list .row.rowHeader .icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.list .row > .right {
  margin-left: auto;
  flex: none;
}

.list .row .buttonPlaceholder {
  /*width: 20px;*/
  flex: none;
}

.list .row .size0c {
  width: 50px;
}

.list .row .size1c {
  width: 50px;
}

.list .row .size2c {
  width: 100px;
}
.list .row  .max--size2c {
  max-width: 100px;
}

.list .row .size3c {
  width: 150px;
}

.list .row .size4c {
  width: 200px;
}

.list .row .size5c {
  width: 250px;
}

.list .row .size6c {
  width: 300px;
}

.list .row .size7c {
  width: 400px;
}

.list .row .size8c {
  width: 500px;
}

.list .row .flex0c {
  flex: none;
  max-width: 50px;
}

.list .row .flex1c {
  flex: 1 0;
  max-width: 50px;
}

.list .row .flex2c {
  flex: 2 0;
  max-width: 100px;
}

.list .row .flex3c {
  flex: 2.5 0;
  max-width: 150px;
}

.list .row .flex4c {
  flex: 3 0;
  max-width: 200px;
}

.list .row .flex5c {
  flex: 3.5 0;
  max-width: 250px;
}

.list .row .flex6c {
  flex: 4 0;
  max-width: 300px;
}

.list .row .flex7c {
  flex: 5 0;
  max-width: 400px;
}

.list .row .flex8c {
  flex: 6 0;
  max-width: 500px;
}

.list .row .flexRest {
  flex: 6 0;
}

.list .row .size8c.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list .row .size9c {
  /*flex: 7 0;
  max-width: 600px;*/
  width: 600px;
}

.list .row .newLine {
  clear: left;
  margin-left: 32px;
}

.list .row input,
 table tr input {
  background: rgba(255, 255, 255, 0.2);
  margin-top: 1px;
  margin-bottom: 1px;
  float: left;
  padding-right: 6px;
}

.list .row input[type='checkbox'],
table tr input[type='checkbox'] {
  margin-top: 4px;
  margin-right: 4px;
}

.list .row select {
  background: rgba(255, 255, 255, 0.2);
  margin-top: 1px;
  margin-bottom: 1px;
  float: left;
}

.list .row textarea {
  background: rgba(255, 255, 255, 0.2);
  margin-top: 1px;
  margin-bottom: 1px;
  float: left;
}

.list .row audio {
  height: 22px;
  width: 310px;
}

.list li .button.audioControl {
  padding: 1px 3px;
}

.mainPanel .imageContainer {
  width: 320px;
  border: 1px solid var(--main-color);
  padding: 0 9px 9px 9px;
  margin: 0 9px 9px 0;
  overflow: hidden;
}

.mainPanel .imageContainer img {
  cursor: pointer;
}

.mainPanel .imageContainer .text {
  min-height: 20px;
  clear: left;
}

.header-row {
  background-color: var(--main-color);
}

.list .icon-placeholder {
  width: 20px;
  height: 20px;
}

.list .sort-icon.reversed {
  transform: rotate(180deg);
}

.list .sort-icon.pointRight {
  transform: rotate(-90deg);
}

.list .sort-icon {
  width: 16px;
}

.list .rowHeader .sortable:hover {
  cursor: pointer;
  background-color: var(--hover-red-color);
}

.list .rowHeader .sortable .sort-icon {
  position: relative;
  width: 14px;
  height: 14px;
  right: -10px;
  top: 1px;
  fill: white !important;
}

.list .rowHeader .button:hover {
  background-color: var(--hover-red-color);
  cursor: pointer;
}

.list .rowHeader .sortable:hover .sort-icon {
  fill: #000 !important;
}
.list .rowHeader .sortable:hover .sort-icon {
  fill: white  !important;
}
.list .row.mail {
  flex-direction: column;
}

.list .row.mail .row {
  display: flex;
  width: calc(100% - 12px);
}

.list .row.mail .row > div:nth-of-type(1){
  padding-left: 0;
}

.expandableTextSection {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.expandableTextSection div {
  flex: none;
  margin-left: 30px;
}

.expandableTextSection pre {
  flex: 1 0;
  margin-left: 10px;
  margin-bottom: 0;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.expandableTextSection .shrinked {
  overflow: hidden;
  height: 18px;
}

.list.change-log .size4c {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list.change-log .size4c.end {
  direction: rtl;
}

.content .files-list {
  display: flex;
  margin-top: 9px;
  flex-wrap: wrap;
}

.ant-popover-inner-content {
  padding: 6px 8px !important;
}

.events__filter{
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 5px;  
  flex-wrap: wrap;
}
.events__filter div{
  display: flex;
  gap: 5px;
  align-items: center;
}

.flex__row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row__note{
  margin-top: 5px;
}
li .row .flex__row .flex__row--row .flex3c {
  width: 150px;
  padding-left: 6px;
  padding-right: 6px;
}

li .row .flex__row .flex__row--row .flex4c {
  width: 200px;
  padding-left: 6px;
  padding-right: 6px;
}

li .row .flex__row .flex__row--row .flex5c {
  width: 250px;
  padding-left: 6px;
  padding-right: 6px;
}

li .flex__row .flex__row--row {
  padding: 0px !important;
}
.margin12{
  margin-left:12px
}
.margin30{
  margin-left:30px
}
.rowAccessories{
  background-color:var(--tab-color) ;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.rowAccessories .flex{
  flex-wrap: wrap;
}
.rowAccessories .flex .invalid{
  background: var(--light-red-color);
}
.rowAccessories div input,
.rowAccessories div select{
  margin-left: 2px;
}
.rowAccessories .flex{
  width:100%;
}
.rowAccessories .flex .flex{
  padding:2px;
}
.rowAccessories .button{
  height: 20px;
  margin: 3px
}
.stretched.address{
  flex-wrap: nowrap;
  margin-left: 23px ;
}

.inputReplacement.redInput.width{
  width: 150px;
}
.flex__row--row{
  display: flex;
  flex-wrap: wrap;
}
.row.withHover .row.noLeftPadding{
  padding-left: 0;
  display: flex;
  width: 100%;
}

.borderPlaceholder {
  width: 7px;
  padding: 0px !important;
}
.border--borderPlaceholder {
  border-left: 7px;
}
.note{
  display: flex;
  flex-direction: column;
}
.margin7{
  margin-left: 7px;
}
.controlsHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.controlsHeader div{
  display: flex;
  gap:5px
}