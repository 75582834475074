
.icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.iconSection {
  width: 30px;
  height: 30px;
  background-size: 26px 26px;
}

.iconBig {
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
}
.header--flex .group__icon,.group__icon{
  width: 26px;
  height: 26px;
  border-radius: 3px;
  border: 0.5px solid white;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group__icon .dotted{
  width: 23px;
  height: 23px;
  border-radius: 3px;
  border: 1px dotted white;
  display: flex;
  justify-content: center ;
  align-items: center;
}

.icon.newWindow {
  background-image: url("../icons/icons8-restore-a-window.png");
}

.icon.error {
  background-image: url("../icons/icons8-no.png");
}

.icon.alert {
  background-image: url("../icons/icons8-alert.png");
}

.icon.merge {
  background-image: url("../icons/icons8-merge-docunemts.png");
}

.icon.reload {
  background-image: url("../icons/icons8-reload.png");
}

.icon.save {
  background-image: url("../icons/icons8-save.png");
}

.icon.remove {
  background-image: url("../icons/icons8-delete.png");
}

.icon.edit {
  background-image: url("../icons/icons8-edit.png");
}

.icon.cancel {
  background-image: url("../icons/icons8-cancel.png");
}

.icon.ok {
  background-image: url("../icons/icons8-ok.png");
}

.icon.open {
  background-image: url("../icons/icons8-open.png");
}

.icon.back {
  background-image: url("../icons/icons8-return-50.png");
}

.icon.upload {
  background-image: url("../icons/icons8-upload-symbol-50.png");
}

.icon.download {
  background-image: url("../icons/icons8-download-from-ftp-50.png");
}

.icon.add {
  background-image: url("../icons/icons8-plus-math-50.png");
}

.icon.police {
  background-image: url("../icons/icons8-police-uniform-50.png");
}

.icon.select {
  background-image: url("../icons/icons8-plus-math-50.png");
}

.icon.deselect {
  background-image: url("../icons/icons8-subtract-50.png");
}

.icon.filter {
  background-image: url("../icons/icons8-filter-50.png");
}

.icon.document {
  background-image: url("../icons/icons8-rules-50.png");
}
.icon.note {
  background-image: url("../icons/icons8-documents.png");
}

/* ---------------------------------------------------- */
/*
.icon.taskCaseActivated {
  background-image: url("../icons/icons8-new-file.png");
}

.icon.taskCaseClose {
  background-image: url("../icons/icons8-file-delete.png");
}

.icon.taskCaseUnhandled {
  background-image: url("../icons/icons8-anonymous-user.png");
}

.icon.taskGeneric {
  background-image: url("../icons/icons8-todo-list.png");
}

.icon.taskUnassignedCases {
  background-image: url("../icons/icons8-anonymous-user.png");
}

.icon.taskUnknown {
  background-image: url("../icons/icons8-unknown-location.png");
}
*/
.icon.taskCollection {
  background-image: url("../icons/icons8-tow-truck-50.png");
}
.icon.taskContact {
  background-image: url("../icons/icons8-phone-outline.png");
}
.icon.taskGeneric {
  background-image: url("../icons/icons8-todo-list.png");
}
.icon.taskPaymentPromise {
  background-image: url("../icons/icons8-payment-history.png");
}
.icon.taskVisit {
  background-image: url("../icons/icons8-building-outline.png");
}
.icon.taskVtpPromise {
  background-image: url("../icons/icons8-task-planning-50.png");
}
.icon.semaphore {
  background-image: url("../icons/icons8-traffic-light-100.png");
}
.icon.taskCompleted {
  background-image: url("../icons/icons8-task-completed-50.png");
}
/* ---------------------------------------------------- */

.icon.tasks {
  background-image: url("../icons/icons8-todo-list.png");
}

.icon.task {
  background-image: url("../icons/icons8-task-50.png");
}

.icon.taskAdd {
  background-image: url("../icons/icons8-task-planning-50.png");
}

.icon.statistics {
  background-image: url("../icons/icons8-task-planning-50.png");
}

.icon.dropBox {
  background-image: url("../icons/icons8-folder.png");
}

.icon.dropBoxAdd {
  background-image: url("../icons/icons8-add-folder.png");
}

.icon.dropFile {
  background-image: url("../icons/icons8-file.png");
}

.icon.users {
  background-image: url("../icons/icons8-team.png");
}

.icon.user {
  background-image: url("../icons/icons8-user-avatar.png");
}

.icon.unknownUser {
  background-image: url("../icons/icons8-anonymous-user.png");
}

.icon.userAdd {
  background-image: url("../icons/icons8-add-user-male.png");
}

.icon.userClear {
  background-image: url("../icons/icons8-anonymous-user.png");
}

.icon.rights {
  background-image: url("../icons/icons8-lock-outline.png");
}

.icon.addressBook {
  background-image: url("../icons/icons8-human-resource.png");
}

.icon.addressBookItem {
  background-image: url("../icons/icons8-address-book-2.png");
}

.icon.addressBookItemAdd {
  background-image: url("../icons/icons8-add-book.png");
}

.icon.address {
  background-image: url("../icons/icons8-address.png");
}

.icon.gps {
  background-image: url("../icons/icons8-location-off-50.png");
}

.icon.expectedGps {
  background-image: url("../icons/icons8-location-50.png");
}

.icon.email {
  background-image: url("../icons/icons8-email-envelope.png");
}

.icon.letter {
  background-image: url("../icons/icons8-email-envelope.png");
}

.icon.phone {
  background-image: url("../icons/icons8-phone-outline.png");
}

.icon.disconnect {
  background-image: url("../icons/icons8-hang-up-50.png");
}

.icon.transfer {
  background-image: url("../icons/icons8-call-transfer-50.png");
}

.icon.sms {
  background-image: url("../icons/icons8-sms-50.png");
}

.icon.call {
  background-image: url("../icons/icons8-phone-outline-50-2.png");
}

.icon.communication {
  background-image: url("../icons/icons8-outgoing-phone-call-50.png");
}

.icon.messageTemplate {
  background-image: url("../icons/icons8-term.png");
}

.icon.import {
  background-image: url("../icons/icons8-import.png");
}

.icon.importAdd {
  background-image: url("../icons/icons8-add-file.png");
}

.icon.export {
  background-image: url("../icons/icons8-export.png");
}

.icon.campaigns {
  background-image: url("../icons/icons8-flow-chart.png");
}

.icon.campaign {
  background-image: url("../icons/icons8-folder.png");
}

.icon.campaignAdd {
  background-image: url("../icons/icons8-add-folder.png");
}

.icon.accessory {
  background-image: url("../icons/icons8-file.png");
}

.icon.accessoryAdd {
  background-image: url("../icons/icons8-add-file.png");
}

.icon.cases {
  background-image: url("../icons/icons8-open-folder-in-new-tab.png");
}

.icon.case {
  background-image: url("../icons/icons8-folder.png");
}

.icon.caseAdd {
  background-image: url("../icons/icons8-add-folder.png");
}

.icon.connect {
  background-image: url("../icons/icons8-connect-100.png");
}

.icon.files {
  background-image: url("../icons/icons8-copy.png");
}

.icon.log {
  background-image: url("../icons/icons8-log.png");
}

.icon.file {
  background-image: url("../icons/icons8-file.png");
}

.icon.car {
  background-image: url("../icons/icons8-car.png");
}

.icon.company {
  background-image: url("../icons/icons8-building-outline.png");
}

.icon.creditCard {
  background-image: url("../icons/icons8-visa.png");
}

.icon.agreement {
  background-image: url("../icons/icons8-agreement.png");
}

.icon.money {
  background-image: url("../icons/icons8-money.png");
}

.icon.time {
  background-image: url("../icons/icons8-clock-outline-50.png");
}

.icon.law {
  background-image: url("../icons/icons8-law-50.png");
}

.icon.department {
  background-image: url("../icons/icons8-department.png");
}

.icon.moneyPartial {
  background-image: url("../icons/icons8-payment-history.png");
}

.icon.coins {
  background-image: url("../icons/icons8-stack-of-coins.png");
}

.icon.person {
  background-image: url("../icons/icons8-user-avatar.png");
}

.icon.relation {
  background-image: url("../icons/icons8-company-2.png");
}

.icon.locked {
  background-image: url("../icons/icons8-lock.png");
}

.icon.lock {
  background-image: url("../icons/icons8-lock.png");
}

.icon.unlock {
  background-image: url("../icons/icons8-lock-outline.png");
}

.icon.search {
  background-image: url("../icons/icons8-search.png");
}

.icon.checkup {
  background-image: url("../icons/icons8-search.png");
}

.icon.imageRotate0 {
  background-image: url("../icons/icons8-photo-50.png");
}
.icon.imageRotateLeft90 {
  background-image: url("../icons/icons8-photo-50.png");
  transform: rotate(-90deg);
}
.icon.imageRotateRight90 {
  background-image: url("../icons/icons8-photo-50.png");
  transform: rotate(90deg);
}
.icon.imageRotate180 {
  background-image: url("../icons/icons8-photo-50.png");
  transform: rotate(180deg);
}

.icon.image {
  background-image: url("../icons/icons8-photo-50-3.png");
}

.icon.info {
  background-image: url("../icons/icons8-info-50.png");
}

.icon.print {
  background-image: url("../icons/icons8-print-50.png");
}

.icon.dragDrop {
  background-image: url("../icons/icons8-move-grabber-50.png");
}

.icon.resize {
  background-image: url("../icons/icons8-resize-50.png");
}

.icon.inProgress {
  background-image: url("../icons/icons8-circular-arrow-pointing-to-right.png");
}

.icon.rotate {
  background-image: url("../icons/icons8-circular-arrow-pointing-to-right.png");
}

.icon.tow {
  background-image: url("../icons/icons8-tow-truck-50.png");
}

.icon.located {
  background-image: url("../icons/icons8-radar-50.png");
}

.icon.wheel {
  background-image: url("../icons/icons8-wheel-50.png");
}

.icon.objectChecked {
  background-image: url("../icons/icons8-tick-box-50.png");
}

.icon.licensePlate {
  background-image: url("../icons/icons8-car-insurance.png");
}

.icon.notAssignedCollector {
  background-image: url("../icons/icons8-add-user-male-100.png");
}

.icon.beforeStartDate {
  background-image: url("../icons/icons8-expired-100.png");
}

.icon.maintenance {
  background-image: url("../icons/icons8-maintenance-100.png");
}

.icon.caseChanged {
  background-image: url("../icons/icons8-unknown-location.png");
}

.icon.unknown {
  background-image: url("../icons/icons8-unknown-location.png");
}

.icon.payment {
  background-image: url("../icons/icons8-cash-in-hand.png");
}

.icon.flag {
  background-image: url("../icons/icons8-flag-2-50.png");
}

.icon.loading {
  background-image: url("../icons/icons8-sand-clock.png");
  animation: loadRotation 2s infinite linear;
  -webkit-animation: loadRotation 2s infinite linear;
}
.icon.missingSignature {
  background-image: url("../icons/icons8-agreement.png");
}
.icon.weakPassword {
  background-image: url("../icons/icons8-forgot-password-50.png");
}
.icon.expired {
  background-image: url("../icons/icons8-expired-50.png");
}
.icon.showPassword {
  background-image: url("../icons/icons8-eye-50.png");
}

.icon.demo {
  background-image: url("../icons/icons8-eye-50.png");
}
.icon.hidden {
  background-image: url("../icons/icons8-eye-50.png");
}

@keyframes loadRotation {
  0% { -webkit-transform: rotate(0deg); }
  25% { -webkit-transform: rotate(180deg); }
  50% { -webkit-transform: rotate(180deg); }
  75% { -webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@-webkit-keyframes loadRotation {
  0% { -webkit-transform: rotate(0deg); }
  25% { -webkit-transform: rotate(180deg); }
  50% { -webkit-transform: rotate(180deg); }
  75% { -webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(360deg); }
}