/* list header ----------------------------------- */


/* list frame ----------------------------------- */

.tableContainer {
  margin-top: 0px !important;
  width: 100%;
  height: calc(100% - 22px);
  overflow: auto;
  position: relative;
}

.content .listHeader + .listHeader + .tableContainer {
  height: calc(100% - 57px);
}

table {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  
  border-collapse: separate !important;
  border-spacing: 0px 2px;
  margin-top: 0px !important;

  overflow: hidden;
  display: unset;
}

/*list li > .row */
table thead:not(.ant-picker-content thead) {
  /*background:  #d18f91; #ffebeb #dbcfcb*/
  background: var(--main-color);
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
}
table tbody td:last-child,
table thead td:last-child{
  position: sticky;
  right: 0;
  z-index: 2;
  padding-left: 10px;
}

table tr.contact  {
  background-color: var(--tab-color);
}

table td {
  height: 28px;
  z-index: -100;
}

/*.list li:nth-child(even) > .row  {
 background: #dbcfcb
}*/

table td.orangeBorder{
  background-color: var(--orange-color) !important;
  border-radius: 5px 0px 0px 5px; 
  width:5px;
}
table td.redBorder{
  background: var(--invalid-color) !important;
  border-radius: 5px 0px 0px 5px; 
  width:5px;
}
table td.greenBorder{
  background: var(--green-color) !important;
  border-radius: 5px 0px 0px 5px; 
  width:5px;
}

table tr.row.orangeBorder,
table tr.row.orangeBorder td:last-child{
  background: var( --light-orange-color);
}
table tr.row.redBorder,
table tr.row.redBorder td:last-child{
  background: var(--light-red-color);
}
table tr.row.greenBorder,
table tr.row.greenBorder td:last-child{
  background: var(--light-green-color);
}
table tr.row:not(.greenBorder):not(.redBorder):not(.orangeBorder).disabled {
  background-color: white;
}

/*.dialog .list li .row {
  /*background:  #d18f91; #dbcfcb #dbcfcb
  background: white;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1px;
  /*flex-flow: row nowrap;
  align-items: flex-start;*/

/*.dialog .list li:nth-child(even) > .row  {
 background: #dbcfcb;
}*/


/* list row layout --------------------------- */

table tr.row .icons {
  flex: 0 0 auto;
  padding: 2px;
}

table tr.row .icons .icon {
  margin: 1px;
}

table tr.row .buttonIcons {
  flex: 0 0 auto;
}

table tr.row .buttonIcons .button {
  padding: 3px;
}

table td .button {
  padding: 3px;
}

table tr.row .stretched {
  flex: 1 1 auto;
}

table tr.row.rowHeader {
  background: var(--main-color);
  color: white;
}

table tr.row.rowHeader.withRating {
  padding-left: 17px;
}

.list.level1 li .row.rowHeader {
  color: white;
  background-color: var(--main-color);
}

table tr.row .inline{
  display: flex;
  flex: 1 0;
}

table tr.row .icon-button{
  padding: 3px;
  margin-top: -3px;
  margin-left: 5px;
}

table td .button ~ .button {
  margin-left: 3px;
}

table td .button:hover {
  background: #d4d4d4;
  border-radius: 5px;
  height: 20px;
  align-self: center;
}

.padding7{
  padding-left: 7px;
}

table tr.row .score-bar {
  padding: 0;
  width: 5px;
  display: flex;
  align-self: stretch;
}

table tr.row .score-bar.green {
  background-color: #21e24b;
}

table tr.row .score-bar.orange {
  background-color: #ffee00;
}

table tr.row .score-bar.red {
  background-color: #ff0000;
}

table tr.row.multiple-columns {
  flex-direction: column;
}

table tr.row.multiple-columns .row {
  background: white;
  display: flex;
  width: calc(100% - 12px);
  height: unset;
}
table tr.row.multiple-columns .row.disabled{
  margin-bottom: 0;
}
table tr.row.disabled.mail {
  background: #dbcfcb;
  margin-bottom: 2px;
}

table tr.row.disabled.redBorder {
  background: var(--light-red-color) !important;
  margin-bottom: 2px;
}
table tr.row.disabled.orangeBorder {
  background: var( --light-orange-color) !important;
  margin-bottom: 2px;
}
.dialog table tr.row.disabled {
  background: #bfbfbf !important;
  cursor: default !important;
  color: var(--text-color) !important;
}

/*table tr:nth-child(even) > .row.disabled, table tr:nth-child(even) > div > .row.disabled {
  background: #d3edd0;
  margin-bottom: 2px
}*/
table tr.row.selected {
  background: #e8c50a;
}
table tr:nth-child(even) > .row.selected, table tr:nth-child(even) > div > .row.selected {
  background: #fbe25c;
}

table tr.row {
  background: #ffffff;/*#b6bec6;*/
  margin-bottom: 1px;
}

table tr.row.inactive {
  background: #c7cace;/*#b6bec6;*/
  margin-bottom: 1px;
}

table tr.row.withHover {
  cursor: pointer;
}

table tr.withHover:hover > td,
table tr.withHover:hover > td:last-child {
  background: var(--hover-red-color);
  color: white;
  cursor: pointer;
}

table tr.row.withHover:hover .icon,
table tr.row.disabled.withHover:hover .icon,
table tr.row.withHover:hover td:last-child .icon{
  -webkit-filter: invert(100%);
}
table tr.row.withHover:hover .button:hover .icon,
table tr.row.disabled.withHover:hover .button:hover .icon{
  -webkit-filter: invert(0%);
}

tr.row > div:not(.ratingIcon) {
  float: left;
  padding: 3px 6px;
  min-height: 19px;
}

tr.row .ratingIcon {
  margin-left: 5px;
}

table .row.rowHeader > div {
  overflow: hidden;
}

table .row.rowHeader > .highlighted {
  background: #c26062;
  cursor: grab;
}

table thead .icon {
  -webkit-filter: invert(100%);
}

table .row > .right {
  margin-left: auto;
  flex: none;
}

table tr.buttons .button:hover {
  background: #dbcfcb;
}

table td.buttonPlaceholder {
  /*width: 20px;*/
  flex: none;
}
/*
table td.size0c {
  width: 50px;
}

table td.size1c {
  width: 50px;
}

table td.size2c {
  width: 100px;
}
table td .max--size2c {
  max-width: 100px;
}

table td.size3c {
  width: 150px;
}

table td.size4c {
  width: 200px;
}

table td.size5c {
  width: 250px;
}

table td.size6c {
  width: 300px;
}

table td.size7c {
  width: 400px;
}

table td.size8c {
  width: 500px;
}

table td.flex0c {
  flex: none;
  max-width: 50px;
}

table td.flex1c {
  flex: 1 0;
  max-width: 50px;
}

table td.flex2c {
  flex: 2 0;
  max-width: 100px;
}

table td.flex3c {
  flex: 2.5 0;
  max-width: 150px;
}

table td.flex4c {
  flex: 3 0;
  max-width: 200px;
}

table td.flex5c {
  flex: 3.5 0;
  max-width: 250px;
}

table td.flex6c {
  flex: 4 0;
  max-width: 300px;
}

table td.flex7c {
  flex: 5 0;
  max-width: 400px;
}

table td.flex8c {
  flex: 6 0;
  max-width: 500px;
}

table td.flexRest {
  flex: 6 0;
}*/

table td.size8c.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

table td.size9c {
  /*flex: 7 0;
  max-width: 600px;*/
  width: 600px;
}

table .icon-placeholder {
  width: 20px;
  height: 20px;
}

table .sort-icon.reversed {
  transform: rotate(180deg);
}

table .sort-icon.pointRight {
  transform: rotate(-90deg);
}

table .sort-icon {
  width: 16px;
}

table thead .sortable:hover {
  cursor: pointer;
  background-color: var(--hover-red-color);
}

table thead .sortable .sort-icon {
  position: relative;
  width: 14px;
  height: 14px;
  right: -10px;
  top: 1px;
  fill: white !important;
}

.list thead .button:hover {
  background-color: var(--hover-red-color);
  cursor: pointer;
}

table tr .buttons {
  flex: none;
  display: flex;
  min-width: 55px;
  padding: 0px;
  justify-content: flex-end;
  margin-left: auto;
  align-items: center;
}

table thead .sortable:hover .sort-icon {
  fill: #000 !important;
}
table thead .sortable:hover .sort-icon {
  fill: white  !important;
}

.borderPlaceholder {
  width: 7px;
  padding: 0px !important;
}
.border--borderPlaceholder {
  border-left: 7px;
}
.note{
  display: flex;
  flex-direction: column;
}
.margin7{
  margin-left: 7px;
}
.controlsHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.controlsHeader div{
  display: flex;
  gap:5px
}

table thead td {
  position: relative;
}
table .resizable {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}

table .resizable:hover {
  cursor: col-resize;
  background-color: var(--orange-color);
  height: 100vh;
  box-shadow: 0px 0px 15px rgba(81, 57, 57, 1);
}

table .statePlaceholder{
  min-width: 5px;
  width: 5px;
}

table .semaphorePlaceholder{
  min-width: 15px;
  width: 15px;
}

table .iconPlaceholder{
  min-width: 25px;
  width: 25px;
}

table .alignRight {
  text-align: right;
}